<template>
  <div class="side-bar-wrapper">
    <div class="side-bar">
      <div v-show="backTop" class="side-bar-backtop anim-scale" @click="goBackTop"><van-icon :size="22" color="#7d7e80" name="arrow-up" /></div>
    </div>
  </div>
</template>

<script>
import { scrollTo } from '@/common/scroll-to'

export default {
  name: 'Sidebar',
  props: {
    type: {
      type: String,
      default: 'campus'
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      backTop: true

    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', this.onPageScroll, false)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onPageScroll, false)
  },
  methods: {
    onPageScroll() {
      const sTop = document.documentElement.scrollTop || document.body.scrollTop
      this.backTop = sTop >= 500
    },
    goBackTop() {
      scrollTo(0, 800)
    }
  }
}
</script>
<style lang="less" scoped>

  .side-bar{
  position:fixed;
  right: 15px;
  bottom:60px;
  z-index:99;
  min-height:80px;
  &-share,&-backtop{
    box-sizing: border-box;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #7d7e80;
    width: 32px;
    height: 32px;
    box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.anim-scale {
    animation-name: anim-scale;
    animation-duration: .3s;
    animation-fill-mode: both;
}
@keyframes anim-scale {
    0% {
        opacity: .3;
        -ms-transform: scale(.5);
        transform: scale(.5)
    }

    100% {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1)
    }
}
</style>

