<template>
  <div class="container pd100">
    <van-swipe :autoplay="3000" indicator-color="white" class="goods-swiper">
      <van-swipe-item v-for="item in banner" :key="item.id">
        <van-image :src="item.pic" /></van-swipe-item>
    </van-swipe>
    <div class="goods-desc mb10">
      <div class="goods-desc-hd">
        <span class="goods-desc-name">{{ basicInfo.name }}</span>
        <!-- <div class="btn-fav" v-if="hasFav" @click="onFavDelete($route.query.id)">已收藏</div>
        <div class="btn-fav btn-fav-danger"  v-else @click="onFavAdd($route.query.id)">收藏</div>-->
      </div>
      <div class="goods-desc-brief">
        <div class="goods-desc-price">
          <span class="fz12">￥</span>
          <span class="goods-desc-price_min">{{ basicInfo.sale_price }}</span>
        </div>
        <div class="goods-desc-price_original mb10">
          价格
          <span class="ui-text-deleted">￥{{ basicInfo.market_price }}</span>
        </div>
      </div>
      <div class="goods-desc-more van-hairline--top">
        <div class="goods-desc-more-item">
          <!-- <template v-if="basicInfo.product_expfee">
            运费 ￥{{basicInfo.product_expfee}}
          </template>
          <template v-else>
            免运费
          </template>-->
          库存
        </div>
        <!-- <div class="goods-desc-more-item ui-center">购买：{{basicInfo.numberSells}}</div> -->
        <div class="goods-desc-more-item ui-right">剩余 {{ basicInfo.product_instocks }}</div>
      </div>
    </div>

    <div class="goods-section">
      <!-- <van-cell class="mb10"
        title="领券"
        is-link
        @click="onShowCoupon"
      v-if="coupons.length&&!marketing.type" />-->
      <van-cell class="mb10" title="服务 担保交易" is-link />
    </div>
    <template v-if="commentTotal > 0">
      <div class="goods-reputation mb10">
        <van-cell
          :to="'/weistore/comment?product_id='+ $route.query.product_id"
          class="mb10"
          :title="'评价('+commentTotal+')'"
          value="全部评价"
          is-link
        />
        <div class="goods-reputation-inner">
          <div class="goods-reputation-inner-hd">
            <div class="goods-reputation-inner-media">
              <van-image :src="comment.avatar" alt /></div>
            <div class="goods-reputation-inner-name">{{ comment.name }}</div>
            <div class="goods-reputation-inner-stars">
              <van-rate
                :value="comment.rate"
                :size="14"
                :color="globalData.theme.color1"
                allow-half
                void-icon="star"
                void-color="#eee"
                readonly
              />
            </div>
          </div>
          <!-- <div class="goods-reputation-inner-bd"> -->
          <!-- <span class="goods-reputation-inner-tag">质量不错</span> -->
          <!-- <span class="goods-reputation-inner-tag">下次再来</span> -->
          <!-- </div> -->
          <div class="goods-reputation-inner-ft">
            <p>{{ comment.content }}</p>
            <!-- <p>{{reputation.property}}</p> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <van-cell class="mb10" title="暂无评价" />
    </template>
    <div class="goods-h2 common-h2">
      <span class="common-h2-title">商品详情</span>
    </div>
    <div class="goods-content" v-html="basicInfo.product_desc" />
    <!-- 商品规格弹层 -->
    <van-sku
      v-model="skuShow"
      :sku="skuData.sku"
      :initial-sku="initSku"
      :good-id="goodsId"
      :goods="goodInfo"
      @add-cart="onAddCartClicked"
      @buy-clicked="onBuyClicked"
    >
      <template slot="sku-actions" slot-scope="props">
        <van-button size="large" type="danger" @click="props.skuEventBus.$emit('sku:buy')">确定</van-button>
      </template>
    </van-sku>
    <!-- 商品导航栏 -->
    <van-goods-action>
      <van-goods-action-icon icon="wap-home-o" text="首页" to="/home" replace />
      <van-goods-action-icon icon="cart-o" text="购物车" to="/cart" :badge="cartCount" />
      <van-goods-action-button type="warning" text="加入购物车" @click="onShowSku('cart')" />
      <van-goods-action-button type="danger" text="立即购买" @click="onShowSku('buy')" />
    </van-goods-action>
    <!-- 右侧悬浮图标 -->
    <sidebar />
  </div>
</template>
<script>
import {
  Swipe,
  SwipeItem,
  Sku,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Rate
} from 'vant'

import Sidebar from '@/components/Sidebar'
import PageMixin from '@/mixins/page'
import storage from 'good-storage'

export default {
  name: 'WeistoreProduct',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Sku.name]: Sku,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Rate.name]: Rate,
    Sidebar
  },
  mixins: [PageMixin],
  data() {
    return {
      basicInfo: {},
      cartCount: null,
      banner: [],
      commentList: [],
      comment: [],
      commentTotal: 0,
      // sku
      skuShow: false,
      goodsId: '',
      goodInfo: {},
      initSku: {},
      skuData: {
        sku: {
          price: '1.00', // 默认价格（单位元）
          stock_num: 100, // 商品总库存
          none_sku: false, // 是否无规格商品
          hide_stock: false, // 是否隐藏剩余库存
          // collection_id: 2261, // 无规格商品 skuId 取 collection_id，否则取所选 sku 组合对应的 id
          tree: [],
          list: [],
          messages: []
        }
      },
      skuActionType: 'buy' // add,buy
    }
  },
  created() {
    this.queryDetail()
  },
  methods: {
    queryDetail() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.$api
        .store_product({ product_id: this.$route.query.product_id })
        .then(res => {
          this.$toast.clear()
          this.banner = res.data.product_images.map((item, index) => ({
            id: index,
            pic: item
          }))
          this.basicInfo = res.data
          this.goodsId = this.$route.query.product_id
          this.goodInfo = {
            title: this.basicInfo.product_name,
            picture: this.basicInfo.product_image,
            price: this.basicInfo.sale_price
          }
          this.queryComment()
          this.queryCartCount()
          this.setSku()
        })
    },
    queryCartCount() {
      this.$api.cart_count().then(res => {
        this.cartCount = res.data.count ? Number(res.data.count) : null
      })
    },
    addCart({ product_id, spec_id, count }) {
      this.$api
        .cart_set({ product_id, spec_id, count })
        .then(res => {
          this.$toast.success('加入购物车成功')
          this.queryCartCount()
          this.skuShow = false
          if (this.skuActionType === 'buy') {
            this.redirectOrderSubmit()
          }
        })
        .catch(err => {
          this.$toast.fail('加入购物车失败')
          console.error(err)
        })
    },
    redirectOrderSubmit() {
      storage.session.remove('choseCoupon')
      storage.session.remove('choseAddress')
      this.$router.push({ path: '/order-submit' })
    },
    queryComment() {
      this.$api
        .store_productcomment({ product_id: this.$route.query.product_id })
        .then(res => {
          if (res.items.length) {
            const productcomment = res.items.slice(0, 1)[0]
            this.commentTotal = res._meta.totalCount
            this.comment = {
              name: productcomment.commnet_name,
              avatar: productcomment.commnet_avatar,
              rate: Number(productcomment.commnet_rate),
              content: productcomment.commnet_content
            }
          }
        })
    },
    setSku() {
      const product_spec = this.basicInfo.product_spec || []
      // 无规格
      if (!product_spec.length) {
        this.skuData.sku.price = this.basicInfo.sale_price
        this.skuData.sku.stock_num = this.basicInfo.product_instocks
        this.skuData.sku.none_sku = true
        return
      }
      // 一种规格
      if (product_spec.length) {
        const tree = []
        const list = []
        product_spec.forEach((item, index) => {
          tree.push({
            k: item.spec_name,
            k_id: `${item.spec_name}11`,
            v: item.spec_dets.map(spec => ({
              id: spec.spec_id,
              name: spec.det_name,
              price: spec.sale_price,
              sale_price: spec.sale_price,
              market_price: spec.market_price,
              spec_name: spec.det_name,
              stock_num: Number(spec.instock)
            })),
            k_s: 's1'
            // count: item.spec_dets.length
          })
        })
        tree[0].v.forEach(item => {
          list.push({
            id: item.id,
            price: parseFloat((item.price * 100).toFixed(2)), // 价格（单位分）
            s1: item.id,
            stock_num: item.stock_num,
            goodsId: this.goodsId,
            sale_price: item.sale_price,
            market_price: item.market_price,
            spec_name: item.spec_name
          })
        })
        this.skuData.sku.tree = tree
        this.skuData.sku.list = list
        this.initSku = {
          s1: list[0].s1,
          selectedNum: 1,
          selectedProp: {}
        }
      }
    },
    onBuyClicked(data) {
      console.log('onBuyClicked:')
      console.log(data)
      const count = data.selectedNum
      const spec_id = data.selectedSkuComb ? data.selectedSkuComb.id : 0 // 有规格商品取id,无规格取0
      const product_id = this.$route.query.product_id

      this.addCart({ product_id, spec_id, count })
    },
    onAddCartClicked(data) {
      this.$toast('add cart:' + JSON.stringify(data))
    },
    onShowSku(action) {
      this.skuActionType = action
      this.skuShow = true
    },
    // 优惠券
    onShowCoupon() {}
  }
}
</script>
<style lang="less" scoped>
  .xiahua {
    text-decoration: line-through;
    color: #999;
  }
.text-left {
  text-align: left;
}
// .goods-swiper,.goods-swiper img{
//   width: 100%;
//   height: 410px;
// }
.goods-desc {
  box-sizing: border-box;
  padding: 0 15px;
  background: #fff;
  &-hd {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
  }
  &-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    flex: 1;
  }
  &-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #f44;
    &_min {
      font-size: 24px;
      color: #f44;
    }
    &_original {
      font-size: 12px;
      color: #999;
    }
    &_label {
      margin-left: 10px;
      box-sizing: border-box;
      display: inline-block;
      font-size: 10px;
      padding: 2px 4px;
      border-radius: 4px;
      color: #f44;
      border: 1px solid #f44;
    }
  }

  &-brief {
    font-size: 12px;
    color: #888;
    margin-bottom: 10px;
  }

  &-more {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    &-item {
      flex: 1;
      font-size: 12px;
      color: #999;
    }
  }
  .btn-fav {
    box-sizing: border-box;
    display: inline-block;
    font-size: 12px;
    width: 60px;
    text-align: center;
    padding: 2px 10px;
    border-radius: 10em;
    background-color: #fff;
    color: #666;
    border: 1px solid #ebedf0;
  }
  .btn-fav-danger {
    color: #f44;
    border: 1px solid #f44;
  }
}

.goods-content /deep/ img {
  max-width: 100% !important;
  height: auto !important;
}
.goods-content {
  box-sizing: border-box;
  background: #fff;
  min-height: 400px;
}

.goods-reputation {
  background: #fff;
  &-inner {
    padding: 0 15px;
    &-hd {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      font-size: 12px;
      color: #666;
    }
    &-media {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      background: #eee;
      margin-right: 5px;
    }
    &-name {
      flex: 1;
    }
    &-stars {
    }
    &-tag {
      padding: 2px 10px;
      border-radius: 10px;
      border: 1px solid #979797;
      font-size: 10px;
      color: #999;
      margin-right: 10px;
    }
    &-bd {
      padding: 10px 0;
    }
    &-ft {
      padding: 5px 0 10px;
      font-size: 14px;
      color: #999;
    }
  }
}
.service-desc {
  height: 70vh;
  padding: 10px 15px;
  font-size: 14px;
  color: #666;
}
.popup-coupon {
  position: relative;
  padding-top: 44px;
  padding-bottom: 50px;
}
.coupon-list {
  padding: 10px 15px;
  max-height: 70vh;
  overflow-y: auto;
  &-header {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 14px;
    color: #333;
    padding: 10px 0;
    text-align: center;
  }
}
.coupon-list-item {
  box-sizing: border-box;
  height: 76px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffeeee;
  color: #ff4b52;
  margin-bottom: 10px;
  border-radius: 10px;
  &-hd {
    width: 100px;
    text-align: center;
    border-right: 1px slategrey #eee;
  }
  &-money {
    font-size: 24px;
    font-weight: bold;
    // margin-bottom:10px;
  }
  &-moneyHreshold {
    font-size: 12px;
  }
  &-bd {
    flex: 1;
  }
  &-name {
    font-size: 14px;
    padding: 5px 0;
  }
  &-dateEndDays {
    font-size: 12px;
    color: #d7a0a5;
  }
  &-btn {
    margin-right: 25px;
    display: inline-block;
    padding: 0.2em 0.5em;
    color: #fff;
    background: #ff4444;
    font-size: 10px;
    line-height: normal;
    border-radius: 0.8em;
  }
  &-btn-clicked {
    color: #ff4444;
    background: #ffffff;
    border: 1px solid #ff4444;
    pointer-events: none;
  }
}
/* 商品玩法 */
.countdown {
  box-sizing: border-box;
  padding: 0 15px;
  height: 45px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(255, 70, 57, 1) 0%,
    rgba(255, 112, 61, 1) 100%
  );
  &-title {
    font-size: 18px;
    font-weight: bold;
  }
  &-value {
    font-size: 12px;
    color: #fff;
  }
  /deep/.van-count-down,
  .van-divider {
    font-size: 12px;
    color: #fff;
  }
}
.rule {
  background-color: #fffbe8;
  font-size: 14px;
  color: #999;
  padding: 10px 15px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &-title {
      color: #ed6a0c;
      flex: 1;
    }
    &-value {
      font-size: 12px;
    }
  }
  &-body {
    font-size: 12px;
    color: #999;
  }
}
.pintuan {
  &-list {
  }
  &-item {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-media {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 5px;
    }
    &-name {
      flex: 1;
    }
    &-info {
      flex: 1;
      text-align: right;
      margin-right: 10px;
      &__bd {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    &-btn {
      font-size: 14px;
      padding: 8px 15px;
      border-radius: 20px;
      color: #fff;
      background: linear-gradient(
        309deg,
        rgba(255, 112, 61, 1) 0%,
        rgba(255, 70, 57, 1) 100%
      );
    }
    &-btn:active {
      opacity: 0.8;
    }
  }
}
@border-radius: 25px;
.btn-danger {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  display: inline-block;
  border-radius: @border-radius;
  text-align: center;
  padding: 12px 0;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(
    309deg,
    rgba(255, 112, 61, 1) 0%,
    rgba(255, 70, 57, 1) 100%
  );
}
/* 弹层*/
.my-popup {
  box-sizing: border-box;
  background: #fff;
  padding: 25px 46px;
  text-align: center;
  width: 260px;
  border-radius: 16px;
  &-header {
    font-weight: bold;
    color: #1a1a1a;
    font-size: 16px;
    padding: 10px 0;
  }
  &-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 22px;
    color: #bfbfbf;
  }
}
.pintuan-popup {
  padding: 25px 30px;
  &-sub {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #666;
    margin-bottom: 10px;
  }
  &-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  &-avatar + &-avatar {
    margin-left: 10px;
  }
  &-avatar {
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #c0c0c0;
    &--empty {
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.popup {
  position: relative;
  padding-bottom: 50px;
  &-confirm {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &-close {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #969799;
    font-size: 20px;
    text-align: center;
  }
}
</style>

